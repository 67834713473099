body {
    // background-color: #fffdf9;
    background-color: #f9f4ec;
    // font-family: "Inter", sans-serif;
    margin-top: 60px;
}

.site-header {
    border-top: 5px solid #418756;
    border-bottom: 1px solid #e4c99c;
    background: #f7e3be;
}

.site-title .fa-solid {
    color: #418756;
}

.wrapper {
    max-width: -webkit-calc(900px - (30px * 2));
}

.site-title {
    font-weight: 500;
}

.site-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}


.over_wrapper {
    padding: 0 20px;
}

.main-wrapper {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    margin: 20px auto;
}

.page-content {
    padding: 0;
    flex: 1;
}

.trigger .fa-solid {
    margin-right: 5px;
    color: #b49e75;
}


.main-wrapper {
    background-color: #ffffff;
    padding: 0;
    margin: 20px auto;
    overflow: hidden;
}

.hero-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    z-index: 1;
}

.hero-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.hero-content {
    position: relative;
    margin-top:-125px;
    padding:30px;
    color: #000;
    z-index: 2;
}

.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

/* Style for content outside hero-container */
.main-wrapper > .home > *:not(.hero-container) {
    padding: 0 30px;
}

.main-wrapper > .home > *:first-child:not(.hero-container) {
    padding-top: 30px;
}

.main-wrapper > .home > *:last-child {
    padding-bottom: 30px;
}

.post-content {
    padding: 30px;
    overflow-x: auto;
}

.post-header {
    padding: 30px 30px 0px 30px;
    margin-bottom: 0;
}


.hero-container + .post-header {
    margin-top: -100px;
    z-index: 999;
    position: relative;
}

.post-title a {
    color:#000;
}

.post-list li {
    padding: 30px 0;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    margin-bottom:0;
}

.post-list li:first-child {
    padding-top: 0; 
}

.post-list li:last-child {
    border-bottom: none;
}

.post-list img {
    border-radius: 10px;
    width: 200px;
    margin-right: 15px;
    object-fit: cover;
    float: left;
    height: auto;
}

.post-list h3 {
    margin-bottom:0;
}

@media (max-width: 600px) {
    .post-list li {
        flex-direction: column;
        align-items: flex-start;
    }

    .post-list img {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
    }
}



@media screen and (max-width: 600px) {
    .site-nav {
        background-color: #f9f4ec;
        border: none;
    }
    .site-nav .page-link {
        padding: 10px 15px;
        margin:0px;
    }
}


.category_tag {
    border-radius: 5px;
    background-color: #eee;
    padding: 5px 7px;
    margin-right: 5px;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
}

.category_tag.uitleg {
    background-color: #eee3cd; 
}

.category_tag.nieuws {
    background-color: #ffb6b6; 
}

.recent-posts {
    margin-top: 2em;
}

.recent-posts h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
}

.recent-post-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.recent-post-item {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 1em;
}

.recent-post-thumbnail {
    flex: 0 0 80px;
    margin-right: 1em;
}

.recent-post-thumbnail img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.recent-post-info {
    flex: 1;
}

.recent-post-title {
    margin: 0;
    font-size: 1.1em;
}

.recent-post-title a {
    text-decoration: none;
    color: #333;
}

.recent-post-title a:hover {
    text-decoration: underline;
}

.post-meta {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 0.5em;
}

.cta-button {
    text-align: center;
    margin: 20px 0;
}

.cta-button a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #418756;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.back-button {
    margin-bottom: 20px;
}

.back-button a {
    display: inline-block;
    padding: 8px 16px;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.back-button a:hover {
    background-color: #e0e0e0;
}

.back-button svg {
    margin-right: 5px;
    vertical-align: middle;
}

p >a {
    font-weight: bold;
}

li > a {
    font-weight: bold;
}

.cta-button a:hover {
    background-color: #2c5d3a;
}

.footer-providers {
    margin-top: 20px;
}

.footer-providers h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.footer-providers ul {
    list-style-type: none;
    padding: 0;
}

.footer-providers li {
    margin-bottom: 5px;
}

.footer-providers a {
    color: #666;
    text-decoration: none;
}

.footer-providers a:hover {
    text-decoration: underline;
}

.site-footer {
    background-color: #f7e3be;
    padding: 40px 0;
    color: #333;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-section {
    flex: 1;
    margin-right: 30px;
    margin-bottom: 20px;
    min-width: 200px;
}

.footer-section:last-child {
    margin-right: 0;
}

.footer-heading {
    font-size: 1.4em;
    margin-bottom: 15px;
    color: #418756;
}

.footer-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 8px;
}

.footer-links a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #418756;
}

.footer-bottom {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9em;
    color: #666;
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin-right: 0;
        margin-bottom: 30px;
    }
}


.table-container {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.responsive-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.responsive-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

